.modal-bg {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  //   filter: brightness(50%);
  box-sizing: border-box;
}

.poster-container {
  position: fixed;
  //   top: 0;
  z-index: 99;
  width: 100vw;
  height: 100px;
  //   box-sizing: border-box;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;

  img {
    top: 0px;
    width: 50vw;
    height: 100vh;
    transform: translateX(50%);
  }

  span {
    position: fixed;
    z-index: 100;
    color: white;
    font-size: larger;
    top: 0px;
    text-decoration: underline;
    transform: translateX(1010%);
    cursor: pointer;
  }
}
