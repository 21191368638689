/**
 * Brand Colors
 */
$color-brand-green: #164a41;
$color-brand-green-light: #3a5a40;
$color-brand-yellow: #e49e00;
$color-brand-cream: #dda15e;
/**
 * Neutral Colors
 */
$color-white: #fff;

$color-gray-02: #f9f9f9;
$color-gray-07: #eee;
$color-gray-13: #ddd;
$color-gray-27: #bbb;
$color-gray-50: #808080;
$color-gray-60: #666;
$color-gray-73: #444;
$color-gray-93: #131313;

$color-black: #000;
$color-black-light: #222222;

/**
  * Utility Colors
  */
$color-utility-info: #0192d0;
$color-utility-info-light: #d3f2ff;

$color-utility-error: #b12a0b;
$color-utility-error-light: #fdded8;

$color-utility-success: #03804d;
$color-utility-success-light: #d4f3e6;

$color-utility-warning: #a59b15;
$color-utility-warning-light: #fffecf;

/**
 * Themes
 */
$themes: (
  light: (
    color-background: #ffffff,
  ),
  dark: (
    color-background: #37474f,
  ),
);
