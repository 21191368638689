@use '../abstracts' as *;

.upcoming-events-container {
  width: 100%;
  @include flex-column;
  align-items: flex-start;
  padding: 5% 7% 5% 7%;

  .header {
    font-family: Roboto Slab;
    font-size: 1rem;
  }

  .event-box-container {
    width: 100%;
    @include flex-row;
    flex-wrap: wrap;
    column-gap: 5%;
    margin-top: 2rem;

    .event-box {
      @include flex-column;
      background-color: #e5f0e1;
      width: 25rem;
      margin-bottom: 1rem;
      padding: 1rem 2rem;

      .date {
        font-size: 2.5rem;
        font-weight: 700;
        color: #588157;
        margin-bottom: 0.5rem;
      }

      .month {
        font-size: 1.125rem;
        font-weight: 400;
        color: #588157;
      }

      .data {
        margin-top: 1rem;
        color: #588157;
      }
    }
  }
}
