@use '../abstracts' as *;

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .move-about-us {
    background-color: white;
    height: 8em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      width: 8em;
      border: 2px solid $color-brand-green-light;
      display: flex;
      font-family: $font-primary;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2% 3% 2% 3%;
      cursor: pointer;
      font-size: medium;

      &:hover {
        background-color: $color-brand-green-light;
        color: white;
      }
    }
  }
}

.info-cards-conatiner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 80%;

  .info-card {
    width: 15em;
    // background-image: linear-gradient(
    //   to right bottom,
    //   #00aecd,
    //   #00a2a9,
    //   #009480,
    //   #288556,
    //   #44732f
    // );
    background-color: whitesmoke;
    margin-right: 2em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.landing-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  font-family: Roboto Slab;
  font-weight: 500;
  color: #4b4a54;

  .card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5em;

    img {
      width: 20%;
      height: 20%;
    }

    span {
      width: 25%;
      text-align: justify;
      line-height: 1.5rem;
      margin: 0 5% 0 5%;
    }
  }
}
