@use '../abstracts' as *;

.principal-container {
  position: absolute;
  font-family: Roboto Slab;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 3% 0% 3% 0%;
  padding: 3% 5%;
  box-sizing: border-box;

  @include mq(medium) {
    flex-direction: column;
  }

  img {
    width: 20%;
    height: 20%;
    border-radius: 50%;
    margin-right: 6%;

    @include mq(medium) {
      width: 50%;
      height: 50%;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: justify;
    line-height: 1.4rem;
    width: 80%;

    @include mq(medium) {
      align-items: center;
    }

    @include mq(medium) {
      width: 100%;
    }

    h2 {
      font-size: 3rem;
      color: $color-brand-green;

      @include mq(medium) {
        font-size: 2rem;
        text-align: center;
        margin-top: 0.5em;
      }
    }
    span {
      font-size: 1rem;
      margin-left: 0.5rem;
      color: $color-black;

      @include mq(medium) {
        margin-top: 0.2em;
        text-align: center;
      }
    }
    p {
      margin-top: 2%;
      font-size: 1.4rem;
      line-height: 2.5rem;
      height: 100%;

      @include mq(medium) {
        text-align: center;
        font-size: 1rem;
        line-height: 1.6rem;
        width: 90%;
      }
    }
  }
}
