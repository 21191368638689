.amenities-page {
  padding-top: 5%;
}

html {
  scroll-behavior: smooth;
}

body {
  font-weight: normal;
  font-style: normal;
  color: #676f73;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a:hover {
  color: #e49e00;
}

a {
  transition: all 0.3s ease-out 0s;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0px;
  color: #164a41;
}

h1 {
  font-size: 45px;
}

@media (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  h1 {
    font-size: 40px;
  }
}

.section-title span {
  font-size: 25px;
  font-weight: 600;
  color: #e49e00;
  margin-bottom: 20px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  margin: 0px;
}

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.pt-25 {
  padding-top: 25px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-150 {
  padding-top: 150px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-50 {
  padding-bottom: 50px;
}

.service-section .single-service {
  margin-bottom: 30px;
  padding: 30px;
  border-radius: 10px;
  transition: all 0.3s ease-out 0s;
}

.service-section .single-service:hover {
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.service-section .single-service .icon {
  margin-bottom: 40px;
  width: 90px;
  height: 90px;
}

.service-section .single-service .icon img {
  max-width: 100%;
}

.service-section .single-service .content h3 {
  font-weight: 600;
  margin-bottom: 25px;
}

.amenity-section .amenity-img img {
  width: 100%;
}

.amenity-section .amenity-content {
  padding-left: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .amenity-section .amenity-content {
    padding-left: 0;
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .amenity-section .amenity-content {
    padding-left: 0;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .amenity-section .amenity-content {
    padding-left: 0;
    margin-top: 50px;
  }
}

.amenity-section .amenity-content .section-title h1 {
  margin-bottom: 35px;
}

.amenity-section .amenity-content .section-title p {
  margin-bottom: 35px;
}

.reverse {
  flex-direction: row-reverse;
}