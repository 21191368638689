@use '../abstracts' as *;

.slider-container {
  width: 100vw;
  position: relative;
  height: 60vh;
  // display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @include mq(medium) {
    height: 75vh;
  }
}

.slider-card {
  position: absolute;
  width: 100vw;
  height: 60vh;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  @include mq(medium) {
    width: 100%;
    height: 75vh;
  }
}

.card-1 {
  background-image: url("../../assets/images/school-front.jpg");
  @include image-header;
  height: 60vh;
  background-position-y: 60%;

  @include mq(medium) {
    background-position-y: unset;
    background-size: auto 100%;
    background-clip: unset;
    height: 75vh;
  }
}

.card-2 {
  background-image: url("../../assets/images/school-children.jpg");
  @include image-header;
  height: 60vh;
  background-position-y: top;

  @include mq(medium) {
    @include image-header-mobile;
    height: 75vh;
    background-position-y: unset;
    background-size: auto 100%;
    background-clip: unset;
  }
}
