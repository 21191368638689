@use 'colors';
@use 'breakpoints' as b;

@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in colors.$themes {
    .theme-#{$theme-name} & {
      #{$key}: map-get(map-get(colors.$themes, $theme-name), $color);
    }
  }
}

@mixin mq($key) {
  $size: map-get(b.$breakpoints, $key);
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin image-header {
  width: 100vw;
  height: 50vh;
  background-position: center;
  background-clip: content-box;
  background-size: 100%;
  background-repeat: no-repeat;
}

@mixin image-header-mobile {
  height: 60vh;
  background-position-y: unset;
  background-size: auto 100%;
  background-clip: unset;
}
