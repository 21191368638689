@use '../abstracts' as *;

.contact-us-container {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
  box-sizing: border-box;
  padding: 2% 5% 2% 5%;
  font-family: Roboto Slab;
  font-size: 1rem;
  color: white;
  background-color: $color-brand-green;
  margin-top: 1em;

  .site-info-container {
    width: 100%;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    box-sizing: border-box;
    line-height: 1.8rem;

    @include mq(medium) {
      flex-direction: column;
    }

    .contact-info {
      @include flex-row;

      @include mq(medium) {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      h4 {
        color: white;
      }

      & > div:nth-child(1) {
        @include flex-column;
        align-items: center;
        margin-right: 4em;
        color: white;

        @include mq(medium) {
          text-align: center;
          margin-right: 0;
        }
      }

      & > div:nth-child(2) {
        @include flex-column;
        align-items: flex-start;
        // margin-right: 2em;
        border-right: 1px solid white;

        @include mq(medium) {
          width: 80%;
          margin-top: 1em;
          margin-right: 0;
          // align-items: center;
          border: 0;
        }

        .contact-detail {
          @include flex-row;
          align-items: flex-start;
          margin-bottom: 1em;
          margin-right: 2em;

          @include mq(medium) {
            margin: 0;
          }

          > img {
            margin-top: 0.3em;
            margin-right: 1em;
          }
        }
      }
    }
  }

  .site-map {
    @include flex-row;
    justify-content: flex-start;
    border-right: 1px solid white;
    padding: 0 5em 0 0em;

    @include mq(medium) {
      flex-direction: column;
      padding: 0;
      border: 0;
    }

    .col {
      @include flex-column;
      margin: 0 2% 0 2%;

      @include mq(medium) {
        align-items: center;
        margin: 0;
      }

      h4 {
        margin-bottom: 1rem;

        @include mq(medium) {
          margin-top: 1em;
        }
      }
      p {
        margin-bottom: 0.5rem;
      }

      h4,
      p {
        cursor: pointer;
        color: white;
        & :hover {
          color: $color-brand-cream;
        }
      }
    }
  }

  > div:nth-child(2) {
    font-weight: 300;
    width: 65%;
    margin-top: 2em;
    text-align: center;
  }

  > div:nth-child(3) {
    font-weight: 400;
    width: 65%;
    margin-top: 1em;
    text-align: center;
  }

  .social {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;

    @include mq(medium) {
      align-items: center !important;
      margin-top: 1em;
    }

    .social-icons {
      display: flex;
      flex-direction: row;
      padding: 0;

      a {
        margin-right: 1em;
      }

      svg {
        width: 25px;
        height: 25px;

        // :hover {
        //   path {
        //     fill: rgb(255, 158, 44);
        //   }
        //   circle {
        //     fill: rgb(255, 158, 44);
        //   }
        // }
      }
    }
  }
}
