@use '../abstracts' as *;

.navbar {
  width: 100%;
  padding: 1% 4% 1% 4%;
  display: flex;
  flex-wrap: nowrap !important;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  background-color: $color-white;
  position: sticky !important;
  top: 0px;
  z-index: 15;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @include mq(medium) {
    justify-self: center;
  }

  @include mq(small) {
    justify-self: center;
  }

  .logo-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .options-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mq(medium) {
      position: absolute;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: $color-brand-green;
      opacity: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: scroll;
    }
  }

  #logo {
    width: 4em;
    height: 4em;
  }

  #logo-name {
    font-family: Arvo;
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 1%;
    margin-right: 1%;
    color: $color-brand-green;

    @include mq(medium) {
      font-size: 1rem;
      margin-left: 0.5em;
    }
  }

  .nav-options {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-weight: 500;

    @include mq(medium) {
      flex-direction: column;
      justify-self: flex-start;
      align-items: center;
      margin-bottom: 2em;
    }

    .menu-item {
      font-family: Roboto Slab;
      font-size: 1rem;
      cursor: pointer;
      color: $color-brand-green;
      font-weight: 700;
      padding: 2%;
      margin-right: 1%;
      box-sizing: border-box;

      transition: background-color 0.5s;

      &:hover {
        background-color: $color-brand-green-light;
        color: $color-white;
      }

      @include mq(medium) {
        width: 70vw;
        color: white;
        font-size: 2rem;
        margin-top: 10%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .sub-menu {
      position: absolute;
      background-color: $color-brand-green-light;
      // transform-origin: 50% -10px;
      top: 79%;
      transform: translateX(-5.5%) !important;
      min-width: 14%;
      box-sizing: border-box;
      z-index: 5;

      @include mq(medium) {
        width: 100%;
        min-width: unset;
        position: relative;
        background-color: transparent;
        top: 0;
        transform: none;
        margin-top: 5%;
        margin-left: 10%;
      }
    }

    .sub-menu-item {
      padding: 0.8em;
      cursor: pointer;
      color: white;

      &:hover {
        background-color: $color-brand-cream;
      }

      @include mq(medium) {
        font-size: 1.2rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 0.5rem;
      }
    }
  }

  .admission-btn {
    cursor: pointer;
    font-family: Roboto Slab;
    font-size: 1rem;
    color: $color-white;
    font-weight: 700;
    padding: 1%;
    box-sizing: border-box;
    background-color: $color-brand-green-light;
    letter-spacing: 1.5px;

    &:hover {
      text-decoration: underline;
    }
  }
}

// .sub-menu-background {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   transform-origin: 0 0;
//   box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
//     0 10px 10px -5px rgba(0, 0, 0, 0.04);
// }
