@use '../abstracts' as *;

.student-committee-container {
  @include flex-column;
  max-width: 100%;
  align-items: center;

  .head-image {
    @include image-header;
    background-image: url("../../assets/images/crayons.jpg");
    background-position-y: top;

    @include mq(medium) {
      @include image-header-mobile;
    }
  }

  .student-committee-details {
    width: 80%;
    padding: 0 2em;
    @include flex-column;
    margin-top: 3%;

    @include mq(medium) {
      width: 100%;
      font-size: 1.1rem;
    }

    .student-qual-image {
      width: 60%;
      height: 50%;
      transform: translateX(25%);

      @include mq(medium) {
        transform: unset;
        width: 100%;
        height: 50%;
      }
    }

    h1 {
      color: $color-brand-green-light;
      margin-bottom: 0.5em;
      font-weight: bold;

      @include mq(medium) {
        font-size: 2rem;
      }
    }

    p {
      margin-top: 0.5em;
      width: 95%;
      display: inline-block;
      font-size: 1.2rem;
      line-height: 2rem;
      text-align: justify;
      margin-bottom: 2em;

      @include mq(medium) {
        font-size: 1.1rem;
      }
    }
  }
}
