@use '../abstracts' as *;

.school-env-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: $font-primary;
  color: $color-black-light;
  align-items: center;

  .head-image {
    @include image-header;
    background-image: url("../../assets/images/class-empty.jpg");
    background-position-y: top;

    @include mq(medium) {
      @include image-header-mobile;
    }
  }

  h1 {
    color: $color-brand-green-light;
    margin-bottom: 0.5em;
    font-weight: bold;

    @include mq(medium) {
      font-size: 2rem;
    }
  }

  .school-env-details {
    width: 80%;
    padding: 0 2em;
    @include flex-column;
    margin-top: 3%;

    @include mq(medium) {
      width: 100%;
    }

    div {
      margin-top: 0.5em;
      width: 95%;
      display: inline-block;
      font-size: 1.2rem;
      line-height: 2rem;
      text-align: justify;
      margin-bottom: 2em;

      @include mq(medium) {
        font-size: 1rem;
      }
    }

    > :nth-child(2) {
      .classroom-image {
        float: right;
        margin-left: 2rem;
        top: 0px;

        background-image: url("../../assets/images/classroom.jpg");
        width: 600px;
        height: 400px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @include mq(medium) {
          margin: 0;
          float: unset;
          margin-bottom: 1em;

          background-size: auto 100%;
          background-position-x: right;
          width: 80vw;
        }
      }
    }

    > :nth-child(3) {
      .teacher-image {
        float: left;
        margin-right: 2rem;
        top: 0px;

        background-image: url("../../assets/images/peerLearning.jpg");
        width: 600px;
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @include mq(medium) {
          margin: 0;
          float: unset;
          margin-bottom: 1em;

          background-size: auto 100%;
          background-position-x: center;
          width: 80vw;
        }
      }
    }
  }
}
