@use '../abstracts' as *;

.section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto Slab;

  h1 {
    color: $color-brand-green;
    font-size: 3rem;
    text-align: center;
    width: 90%;

    @include mq(medium) {
      font-size: 2.2rem;
    }

    @include mq(small) {
      font-size: 1.8rem;
    }
  }

  .objectives-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 7%;

    @include mq(medium) {
      margin-top: 2%;
    }

    .objective-container {
      width: 20%;
      height: 60vh;
      display: flex;
      padding: 2% 2% 0 2%;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      background-color: $color-brand-cream;
      border-radius: 5px;
      text-align: center;
      position: relative;

      @include mq(medium) {
        width: 40%;
        margin-top: 6em;
      }

      @include mq(small) {
        width: 80%;
        margin-top: 5em;
        height: 50vh;
      }

      img {
        position: absolute;
        width: 40%;
        top: -15%;

        @include mq(large) {
          top: -7%;
        }

        @include mq(medium) {
          width: 30%;
          top: -12%;
        }

        @include mq(small) {
          width: 30%;
          top: -16%;
        }
      }

      span {
        color: white;
        font-size: 1.2rem;

        @include mq(small) {
          font-size: 1rem;
        }

        @include mq(medium) {
          font-size: 1.2rem;
        }
      }
    }
  }
}
