@use '../abstracts' as *;

.gallery-page {
  padding: 1% 5% 5% 3%;

  h3 {
    margin-bottom: 1rem;
  }

  .posters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 3rem;

    @include mq(medium) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    img {
      width: 30%;

      @include mq(medium) {
        width: 90%;
      }
    }
  }
}
