@use '../abstracts' as *;

.management-container {
  @include flex-column;
  align-items: center;
  width: 100%;
  font-family: $font-primary;

  .head-image {
    @include image-header;
    background-image: url("../../assets/images/rules.jpg");

    @include mq(medium) {
      @include image-header-mobile;
    }
  }

  .management-details {
    width: 80%;
    padding: 0 2em;
    @include flex-column;
    margin-top: 3%;

    @include mq(medium) {
      width: 100%;
    }

    h1 {
      color: $color-brand-green-light;
      margin-bottom: 0.5em;
      font-weight: bold;

      @include mq(medium) {
        font-size: 2rem;
      }
    }

    .focus-container {
      li {
        font-weight: 400;
        color: $color-black-light;
        font-size: $font-size-med;
        line-height: 2em;
        list-style-type: none;

        @include mq(medium) {
          font-size: 1.1rem;
        }
      }
    }

    .skills-container {
      width: 100%;
      margin-top: 2em;

      h2 {
        color: $color-brand-green-light;
      }

      .skills-detail-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: $font-size-med;
        line-height: 2em;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          width: 100%;
          margin-top: 3em;
          padding-top: 1em;

          @include mq(medium) {
            flex-direction: column;
            align-items: center;
          }

          > :nth-child(1),
          > :nth-child(2),
          > :nth-child(3) {
            &:before {
              content: " ";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              filter: brightness(60%);
              z-index: 5;
            }
          }

          > :nth-child(1) {
            &:before {
              background-image: url("../../assets/images/thinking.jpg");
            }
          }

          > :nth-child(2) {
            &:before {
              background-image: url("../../assets/images/learning.jpg");
            }
          }

          > :nth-child(3) {
            &:before {
              background-image: url("../../assets/images/leadership.jpg");
            }
          }

          .skill-card {
            width: 25%;
            height: 30em;
            position: relative;

            @include mq(medium) {
              width: 100%;
              margin-bottom: 2em;
            }

            div {
              position: relative;
              z-index: 10;
              color: white;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              padding: 2em;
            }

            h2,
            span {
              margin: 0.5em 0 0.5em 0;
              color: white;
            }

            h2 {
              margin-bottom: 20%;
              font-size: 3rem;
              text-align: center;
              line-height: 3rem;
            }

            span {
              font-weight: 600;
              font-size: larger;
            }
          }
        }
      }
    }
  }
}
