.img-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.feedback-img {
    width : 10rem;
    height : 10rem;
    margin: 1rem;
    border-radius: 40px;
}