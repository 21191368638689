@use '../abstracts' as *;

.vision-container {
  width: 80%;
  font-family: Roboto Slab;
  margin: 3% 0% 3% 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.5rem;

  h2 {
    font-size: 2rem;
  }

  .welcome {
    color: $color-black-light;
    margin-bottom: 2%;
  }
  .school-name {
    color: $color-brand-green-light;
  }

  .bar {
    min-width: 10vw;
    height: 0.3em;
    width: 1em;
    background-color: $color-brand-green-light;
    margin: 2% 0 2% 0;
  }

  p {
    font-size: 1.3rem;
    font-weight: 400;
    color: $color-black-light;
    line-height: 2rem;
  }
}
