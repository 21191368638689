@use '../abstracts' as *;

.academics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 4%;

  .academics-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .academics-heading {
    width: 100%;
    text-align: center;
    padding: 2% 0;
  }
  .tabs {
    width: 100%;
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      li {
        text-align: center;
        padding: 10px 15px;
        margin: 15px;
        color: white;
        background-color: $color-brand-green;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease-out 0s;
      }
      .active-tab {
        background-color: $color-brand-yellow;
        span {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 18px solid $color-brand-yellow;
          position: absolute;
          margin-top: 30px;
          margin-left: -15px;
          transition: all 0.3s ease-out 0s;
        }
      }
    }
  }
  .tab-content {
    width: 90%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h3 {
      text-transform: uppercase;
      border-bottom: 3px solid $color-brand-yellow;
    }
    .timings {
      width: 70%;
      text-transform: uppercase;
      h3 {
        font-size: 20px;
      }
      ul li {
        border-bottom: 1px solid $color-brand-green;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        p {
          font-size: 1rem;
          padding: 0 5px;
          color: black;
        }
        p:first-child {
          font-weight: 400;
        }
      }
    }
    .dos-and-donts {
      ul li {
        display: flex;
        flex-direction: row;
        margin: 15px 0;
        padding: 10px;
        border-radius: 10px;
        transition: all 0.3s ease-out 0s;
        span {
          flex-shrink: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          color: $color-brand-green;
          border: 3px solid $color-brand-yellow;
          font-weight: 700;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          margin: 2px 10px;
        }
        p {
          font-size: 15px;
          line-height: 1.5;
          cursor: default;
        }
      }
      ul li:hover {
        box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
